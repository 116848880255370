.editing-group {
    display: flex;
    flex-direction: column;

    h1 {
        color: white;
    }

    .top {
        .exit {
            // create a nice exit button
            // make it a fixed height
            height: 25px;
            // make it a fixed width
            width: 40px;
            // make it a border radius
            border-radius: 5px;
            color: white;
            font-size: 1.2rem;
            font-weight: 600;
            // make text vertically centered
            line-height: 25px;
            text-align: center;
            // make it a pointer
            cursor: pointer;
            border: 2px solid hotpink;
        }
        margin-bottom: 10px;
        .stats {
            // make an open sans font for the text input
            font-family: "Open Sans", sans-serif;
            // make it a fixed height
            height: 30px;
            color: rgb(106, 106, 106);
            font-size: 1rem;
            font-weight: 600;
            // make text vertically centered
            line-height: 30px;
        }
        a {
            text-decoration: none;
        }
        .stats-button {
            border-radius: 6px;
            background-color: rgb(39, 39, 39);
            border: 1px solid rgb(50, 50, 50);
            color: rgb(199, 199, 199);
            font-family: "Open Sans", sans-serif;
            padding: 5px;
            text-decoration: none;
            font-size: 1rem;
            margin-top: 10px;
            margin-bottom: 10px;
            width: fit-content;
            cursor: pointer;
        }
    }

    .bottom {
        // nice pink red gradient delete button
        .delete {
            // make it a fixed height
            height: 30px;
            // make it a fixed width
            width: 200px;
            // make it a border radius
            border-radius: 5px;
            // make it a pointer
            cursor: pointer;
            // make it a nice gradient
            border: 2px solid rgb(255, 0, 128);
            // make text vertically centered
            line-height: 30px;
            // make it a nice font
            font-family: "Open Sans", sans-serif;
            font-size: 1rem;
            font-weight: 600;
            color: white;
            text-align: center;
            // make it a pointer
            cursor: pointer;
        }

        // make this a nice form
        // make it flexible
        display: flex;
        // make it a column
        flex-direction: column;
        flex-grow: 1;
        // make it a border radius
        border-radius: 5px;

        .text {
            // make an open sans font for the text input
            font-family: "Open Sans", sans-serif;
            // make it a fixed height
            height: 30px;
            color: rgb(106, 106, 106);
            font-size: 1rem;
            font-weight: 600;
            // make text vertically centered
            line-height: 30px;
        }

        .check-box {
            input {
                height: 18px;
                width: 18px;
                background-color: hotpink;
                border: 2px solid hotpink;
            }
        }

        .input-box {
            input {
                // make an input box with a border and is darker than its background
                // make it a fixed height
                height: 28px;
                // make it a fixed width
                width: 300px;
                // make it a border radius
                border-radius: 5px;
                border: 1px solid rgb(50, 50, 50);
                background-color: rgb(11, 11, 11);
                // open sans
                font-family: "Open Sans", sans-serif;
                color: rgb(155, 155, 155);
                font-size: 1rem;
                font-weight: 600;
                // padding
                padding-left: 10px;
                // no outline on click
                outline: none;

                // outline on click to pink
                &:focus {
                    outline: 1px solid rgb(119, 47, 124);
                }
            }
        }

        .input-selection {
            // select box stuff

            // background color
            background-color: rgb(11, 11, 11);
            // border radius
            border-radius: 5px;
            color: rgb(199, 199, 199);
            // open sans
            font-family: "Open Sans", sans-serif;
            font-size: 1rem;
            font-weight: 600;
            // padding
            padding-left: 10px;
            // no outline on click
            outline: none;

            // outline on click to pink
            &:focus {
                outline: 1px solid rgb(119, 47, 124);
            }

            // make it a fixed height
            height: 30px;

            // make it a fixed width
            width: 300px;

            // border
            border: 1px solid rgb(50, 50, 50);
        }

        .create-group {
            // make a nice button with gradient for creating the group
            // make it a fixed height
            height: 30px;
            // make it a fixed width
            width: 250px;
            // make it a border radius
            border-radius: 5px;
            // make it a pointer
            cursor: pointer;
            // make it a gradient
            border: 2px solid hotpink;
            // open sans
            font-family: "Open Sans", sans-serif;
            color: white;
            font-size: 1rem;
            font-weight: 600;
            text-align: center;
            // make text vertically centered
            line-height: 30px;

            margin-top: 10px;
        }
    }
}
