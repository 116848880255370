.members {
    h1 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: white;
    }
    height: 75vh;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
        width: 5px;
        padding: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgb(17, 17, 17);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .members-list {
        max-height: 100%;
        .member {
            display: flex;
            flex-direction: row;
            align-items: center;

            margin-bottom: 5px;
            margin-right: 5px;

            // fill
            flex-grow: 1;
            min-width: calc(100% / 3);

            // have a border
            border: 2px solid rgb(50, 50, 50);
            // have a border radius
            border-radius: 5px;
            // padding
            padding: 8px;
            // transition
            transition: all 0.2s ease-in-out;

            //shadow
            box-shadow: 0px 0px 10px 0px rgb(0, 0, 0);

            // nice hover effect
            &:hover {
                // make it a bit darker
                background-color: rgb(30, 30, 30);
                cursor: pointer;
            }

            .member-name {
                font-size: 1.2rem;
                font-weight: 600;
                color: white;
            }

            .member-role {
                // small and grey
                font-size: 0.8rem;
                font-weight: 600;
                color: rgb(155, 155, 155);
                margin-left: 0.2rem;
                // go to bottom of box
                margin-top: 0.3rem;
            }

            .member-group {
                // large and gradient
                font-size: 1rem;
                font-weight: 600;
                color: white;
                // go to the right of the box with flex
                margin-left: auto;
                // make a nice gradient
                border: 2px solid hotpink;
                // make it a border radius
                border-radius: 5px;
                // padding
                padding: 0.2rem 0.5rem;
                // make it a pointer
                cursor: pointer;
                // make it a nice transition
                transition: all 0.2s ease-in-out;

                // make it a nice hover effect
                &:hover {
                    background-color: hotpink;
                    // make it a bit bigger
                    transform: scale(1.025);
                }
            }

            .member-permission {
                // decent sized
                font-size: 1rem;
                font-weight: 600;
                color: white;
                margin-left: 0.5rem;
            }
        }
    }
}
