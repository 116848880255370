.schedule-page {
    height: 100vh;
    width: 100vw;
    background-color: rgb(55, 55, 55);

    .popup {
        position: absolute;
        // center it
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // make it a fixed height
        max-height: 50vh;
        // make it a fixed width
        max-width: 50vw;
        height: fit-content;
        width: fit-content;
        padding: 20px;
        border-radius: 10px;
        background-color: rgb(19, 19, 19);
        box-shadow: 0 0 10px black;
        border: 2px solid white;
        color: white;
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        .title {
            color: rgb(162, 162, 162);
            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 10px;
        }
        .popup-close {
            margin-top: 20px;
            cursor: pointer;
            color: hotpink;
        }
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .calendar-box {
        max-width: 90vw;
        max-height: 90vh;
        height: fit-content;
        padding-bottom: 20px;
        background-color: rgb(36, 36, 36);
        flex-grow: 1;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .topbar {
            width: 100%;
            border-radius: 10px;
            padding: 20px;
            background-color: rgb(23, 23, 23);
            box-sizing: border-box;
            display: flex;
            box-shadow: 0px 0px 10px 0px rgb(0, 0, 0);
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .subtitle {
                color: white;
                font-size: 1.2rem;
                font-weight: 600;
                margin-left: 10px;
                margin-right: 10px;
            }
            .left {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .group-select {
                    height: 46px;
                    border-radius: 5px;
                    background: rgb(54, 54, 54);
                    color: white;
                    // no outline
                    outline: none;
                    // hover pointer
                    cursor: pointer;
                    // border
                    border: 1px solid rgb(54, 54, 54);
                    font-size: 1.2rem;
                    padding-left: 10px;
                    padding-right: 10px;
                    outline: 2px solid white;
                    &:hover {
                        color: white;
                    }
                }
            }
            .right {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .selector {
                    height: 30px;
                    line-height: 30px;
                    border-radius: 10px;
                    color: hotpink;
                    // no outline
                    outline: none;
                    // hover pointer
                    cursor: pointer;

                    margin-left: 10px;
                    margin-right: 10px;
                    &:hover {
                        color: white;
                    }
                }
            }
        }
        .meat {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            .weekdays {
                margin-top: 15px;
                margin-bottom: 15px;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .day {
                    box-sizing: border-box;
                    height: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    //border: 0.5px solid rgb(125, 125, 125);
                    width: calc(100% / 7);
                    .weekday-name {
                        color: white;
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                }
            }
            .days {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
                .empty,
                .day {
                    width: calc(100% / 7);
                    height: 120px;
                    box-sizing: border-box;
                }
                .day {
                    box-sizing: border-box;
                    //border: 0.5px solid rgb(125, 125, 125);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    .day-number {
                        width: 100%;
                        text-align: center;
                        color: rgb(163, 163, 163);
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                    .event {
                        width: 95%;
                        border-bottom: 2px solid hotpink;
                        padding-left: 5px;
                        padding-right: 5px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        &:hover {
                            background-color: rgb(54, 54, 54);
                            cursor: pointer;
                        }

                        .event-name {
                            color: white;
                            font-size: 1rem;
                            font-weight: 600;
                        }
                        .event-time {
                            color: rgb(163, 163, 163);
                            font-size: 1rem;
                            font-weight: 600;
                        }
                        .event-group {
                            color: rgb(163, 163, 163);
                            font-size: 1rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
