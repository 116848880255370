.member-editor {
    .delete {
        // make it a fixed height
        height: 30px;
        // make it a fixed width
        width: 200px;
        // make it a border radius
        border-radius: 5px;
        // make it a pointer
        cursor: pointer;
        // make it a nice gradient
        border: 2px solid red;
        // make text vertically centered
        line-height: 30px;
        // make it a nice font
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: white;
        text-align: center;
        // make it a pointer
        cursor: pointer;
        margin-top: 30px;
        &:hover {
            background-color: red;
            scale: 1.05;
        }
    }
    a {
        text-decoration: none;
    }
    .email-link {
        margin-top: 10px;

        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 6px;
        background-color: rgb(39, 39, 39);
        border: 1px solid rgb(50, 50, 50);
        color: rgb(199, 199, 199);
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        width: fit-content;
        cursor: pointer;
    }
    .exit {
        // create a nice exit button
        // make it a fixed height
        height: 35px;
        // make it a fixed width
        width: 60px;
        // make it a border radius
        border-radius: 5px;
        color: white;
        font-size: 1.2rem;
        font-weight: 600;
        // make text vertically centered
        line-height: 35px;
        text-align: center;
        // make it a pointer
        cursor: pointer;
        margin-bottom: 20px;
        border: 2px solid white;
    }
    .input-selection {
        // select box stuff

        // background color
        background-color: rgb(11, 11, 11);
        // border radius
        border-radius: 5px;
        color: rgb(199, 199, 199);
        // open sans
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        // padding
        padding-left: 10px;
        // no outline on click
        outline: none;

        // outline on click to pink
        &:focus {
            outline: 1px solid rgb(119, 47, 124);
        }

        // make it a fixed height
        height: 30px;

        // make it a fixed width
        width: 300px;

        // border
        border: 1px solid rgb(50, 50, 50);
    }
    margin-bottom: 10px;
    .member-editor-form {
        .member-editor-form-field {
            .label {
                font-family: "Open Sans", sans-serif;
                height: 30px;
                color: rgb(106, 106, 106);
                font-size: 1rem;
                font-weight: 600;
                line-height: 30px;
            }
            input {
                // make an input box with a border and is darker than its background
                // make it a fixed height
                height: 28px;
                // make it a fixed width
                width: 300px;
                // make it a border radius
                border-radius: 5px;
                border: 1px solid rgb(50, 50, 50);
                background-color: rgb(11, 11, 11);
                // open sans
                font-family: "Open Sans", sans-serif;
                color: rgb(155, 155, 155);
                font-size: 1rem;
                font-weight: 600;
                // padding
                padding-left: 10px;
                // no outline on click
                outline: none;

                // outline on click to pink
                &:focus {
                    outline: 1px solid rgb(119, 47, 124);
                }
            }
        }
        .button {
            // make a nice button with gradient for creating the group
            // make it a fixed height
            height: 30px;
            // make it a fixed width
            width: 250px;
            // make it a border radius
            border-radius: 5px;
            // make it a pointer
            cursor: pointer;
            // make it a gradient
            border: 2px solid hotpink;
            // open sans
            font-family: "Open Sans", sans-serif;
            color: white;
            font-size: 1rem;
            font-weight: 600;
            text-align: center;
            // make text vertically centered
            line-height: 30px;

            margin-top: 10px;

            &:hover {
                background-color: rgba(255, 105, 180, 0.444);
            }
        }
    }
}
