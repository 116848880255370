.nav-bar {
    display: flex;

    // every item is gonna be vertical
    flex-direction: column;
    // we are going to be a fixed width
    max-width: 35vw;
    width: fit-content;
    // we are going to stretch to the height of the page
    height: 100vh;

    background-color: rgb(21, 21, 21);

    // gradient border to make it look like a glowing, floating box
    border-right: 1px solid rgb(93, 58, 117);
    border-image: linear-gradient(to bottom, rgb(255, 255, 255), rgb(190, 19, 229)) 1;

    // make children vertically centered
    align-items: center;
    .logo {
        width: 50%;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .title {
        font-family: "Open Sans", sans-serif;
        font-size: 1.5rem;
        font-weight: 100;
        color: white;
        text-align: center;
        margin-bottom: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .selected {
        text-align: left;

        // nice open sans font
        font-family: "Open Sans", sans-serif;
        // font size
        font-size: 1.2rem;
        // font weight
        font-weight: 300;

        color: rgb(255, 227, 249);

        width: 100%;
        // padding
        padding: 10px 0px 10px 40px;
        margin-bottom: 2px;
        margin-top: 2px;
        border-radius: 8px;
        // turn lighter
        background-color: rgb(30, 30, 30);

        // add slight shadow
        box-shadow: 2px black;
        border: 2px solid pink;
        margin: 0;
    }

    .nav-item {
        // all items should be the width of the parent
        // all text inside items should be centered
        text-align: left;

        // nice open sans font
        font-family: "Open Sans", sans-serif;
        // font size
        font-size: 1.2rem;
        // font weight
        font-weight: 300;

        color: rgb(255, 245, 253);

        width: 100%;
        // padding
        padding: 10px 0px 10px 40px;
        margin-bottom: 2px;
        margin-top: 2px;

        // nice hover effect
        &:hover {
            border-radius: 15px;
            // turn lighter
            background-color: rgb(30, 30, 30);

            // add slight shadow
            box-shadow: 2px black;
            border: 2px solid pink;
            margin: 0;
            cursor: pointer;
        }
    }
}
