.content-page {
    // make padding decent
    padding: 5vw;

    // fill rest of flex space
    flex: 1;

    // make a gradient wave in top half of page
    /*.gradient-wave {
        // make it a fixed height
        height: 200px;
        // make it a fixed width
        width: 100%;

        // make it a gradient
        background: linear-gradient(to right, rgb(255, 9, 9), rgb(190, 19, 229));
    }*/

    .content-box {
        // make color darker than background
        background-color: rgb(17, 17, 17);

        // make padding decent
        padding: 20px;

        //border radius
        border-radius: 5px;

        // add slight grey border
        border: 1px solid rgb(50, 50, 50);
    }
}
