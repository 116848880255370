// import open sans from google
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

.home {
  // open sans
  font-family: "Open Sans", sans-serif;
  background-color: rgb(35, 35, 35);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: flex-start;
  background-image: url("../Assets/Images/Polygons.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .topbar {
    position: relative;
    box-sizing: border-box;
    width: 85vw;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        height: 50px;
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .title {
        font-weight: 100;
        margin-left: 10px;
        margin-right: 10px;
        color: white;
        font-family: "Open Sans", sans-serif;
        font-size: 1.5rem;
      }
    }

    .topbar-buttons {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .button {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: rgb(190, 190, 190);
        font-family: "Open Sans", sans-serif;
        font-size: 1.1rem;
        font-weight: 600;
        // prevent highlighting
        user-select: none;
        // hover pointer
        cursor: pointer;
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          color: white;
        }
      }
      .join {
        border: 2px solid rgb(217, 217, 217);
        padding: 4px 20px 4px 20px;
        border-radius: 8px;
        transition: 0.3s;
        &:hover {
          background-color: white;
          border-color: white;
          color: transparent;
        }
      }
    }
  }

  .section-1 {
    // center it
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .section-1-title {
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 2.5rem;
      font-weight: 600;
      margin-left: 30px;
      margin-right: 30px;
      max-width: 80vw;
      text-align: center;
    }
    .section-1-subtitle {
      color: rgb(207, 207, 207);
      font-family: "Open Sans", sans-serif;
      font-size: 1.5rem;
      font-weight: 200;
      text-align: center;
      margin-top: 30px;
      max-width: 75vw;
    }
    .button {
      border: 2px solid hotpink;
      border-radius: 10px;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 1.1rem;
      font-weight: 600;
      // prevent highlighting
      user-select: none;
      // hover pointer
      cursor: pointer;
      text-decoration: none;
      padding: 10px 40px 10px 40px;
      transition: 0.2s ease-in-out;
      margin-top: 40px;
      &:hover {
        background-color: hotpink;
      }
    }
  }
}
