.calendar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;
    // open sans
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
    box-sizing: border-box;
    max-height: 100%;
    // have a scroll bar if needed
    overflow-y: auto;
    // style the scroll bar to be a dark grey rounded bar with no outline
    &::-webkit-scrollbar {
        width: 10px;
        background-color: rgb(54, 54, 54);
        border-radius: 5px;
    }
    .nav {
        display: flex;
        flex-direction: row;
        // space between
        justify-content: space-between;
        margin-bottom: 15px;
        .calendar-text-button {
            margin-left: 5px;
            margin-right: 5px;
            color: hotpink;
            // font and good font size
            font-family: "Open Sans", sans-serif;
            font-size: 1rem;
            font-weight: 600;

            // prevent highlighting
            user-select: none;

            cursor: pointer;
            &:hover {
                color: white;
            }
        }
        .filters-title {
            margin-left: 10px;
        }

        .custom-select {
            width: 200px;
            height: 30px;
            border-radius: 5px;
            background: rgb(54, 54, 54);
            color: white;
            // no outline
            outline: none;
            // hover pointer
            cursor: pointer;
            // border
            border: 1px solid rgb(54, 54, 54);
        }
    }

    .calendar-header {
        display: flex;
        align-items: center;

        flex-direction: row;

        font-size: 1.3rem;
        .calendar-header-year {
            margin-left: 5px;
            color: grey;
        }
        .calendar-header-month {
        }
    }

    .calendar-body {
        flex-direction: column;
        display: flex;
        .calendar-body-weekdays {
            flex-direction: row;
            display: flex;
            .calendar-body-weekdays-day {
                box-sizing: border-box;
                height: 20px;
                //border: 0.5px solid rgb(125, 125, 125);
                flex-basis: calc(100% / 7);
                text-align: center;
            }
        }
        .calendar-body-days {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .calendar-body-days-day {
                box-sizing: border-box;
                border-left: 1px solid rgb(54, 54, 54);
                border-top: 1px solid rgb(54, 54, 54);
                border-right: 1px solid rgb(54, 54, 54);
                border-bottom: 1px solid rgb(54, 54, 54);
                flex-basis: calc(100% / 7);
                max-width: calc(100% / 7);
                min-height: 80px;

                .day {
                    color: rgb(134, 134, 134);
                }

                .today {
                    color: rgb(255, 82, 235);
                }

                flex-grow: 1;
                text-align: center;
                .interior {
                    margin: 5px;
                }
                .date {
                    color: rgb(255, 255, 255);
                    border-radius: 6px;
                    padding: 4px;
                    font-size: 0.75rem;
                    text-align: left;
                    //background: rgb(60, 0, 255);
                    // shadow
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

                    // scale on hover
                    &:hover {
                        cursor: pointer;
                    }
                }

                // hover
                &:hover {
                    background-color: rgb(54, 54, 54);
                    cursor: pointer;
                }
            }
        }
    }
}
