.calendar-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .create-event {
        // nice gradient button
        // make it a fixed height
        height: 30px;
        // make it a fixed width
        width: 250px;
        // make it a border radius
        border-radius: 8px;
        // make it a pointer
        cursor: pointer;
        // make it a gradient
        border: 2px solid hotpink;
        // make it a fixed font size
        font-size: 1rem;
        // make it a fixed font weight
        font-weight: 600;
        // make it a fixed font family
        font-family: "Open Sans", sans-serif;
        // make it a fixed color
        color: white;
        // make it a fixed line height
        line-height: 30px;
        // make it a fixed text align
        text-align: center;

        // transition
        transition: all 0.2s ease-in-out;

        // hover
        &:hover {
            // scale up
            transform: scale(1.025);
        }
    }
}
