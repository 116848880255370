.dash-page {
    width: fit-content;
    min-width: 100vw;
    height: 100vh;
    background-color: rgb(65, 65, 65);
    // items inside of this need to fill the page and be side by side
    display: flex;
    // we want to be a column
    flex-direction: row;
    y-overflow: hidden;

    .page-header {
        //open sans
        font-family: "Open Sans", sans-serif;
        // font size
        font-size: 0.8rem;
        // font weight
        font-weight: 600;
        // color
        color: white;
        width: 100%;
        text-align: center;
    }
}

html {
    background-color: black;
}
