.application {
    &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(212, 20, 20, 0);
        border-radius: 10px;
    }

    // the above scrollbar didnt work properly
    // so we need to style the scrollbar thumb
    &::-webkit-scrollbar-thumb {
        background-color: rgb(113, 113, 113);
        border-radius: 8px;
    }

    // it still doesnt work
    // so we need to style the scrollbar track
    &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 8px;
    }

    //again it doesnt work
    // remove the button
    &::-webkit-scrollbar-button {
        display: none;
    }

    .no-groups {
        color: gray;
        font-family: "Open Sans", sans-serif;
        font-size: 1.2rem;
        margin: 40px;
        text-align: center;
        max-width: 600px;
    }

    background-color: rgb(20, 20, 20);
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 15vh;
    padding-bottom: 15vh;
    // make a nice light grey thin scroll bar

    .input-area {
        margin-top: 15px;
        .input-title {
            // open sans
            font-family: "Open Sans", sans-serif;
            // make it a fixed font size
            font-size: 1.2rem;
            // make it a fixed font weight
            font-weight: 600;
            // make it a fixed color
            color: rgb(180, 180, 180);
            // make it non highlightable
            user-select: none;
            width: 100%;

            margin-left: 10px;
            max-width: 400px;
        }
        .note {
            // open sans
            font-family: "Open Sans", sans-serif;
            // make it a fixed font size
            font-size: 0.8rem;
            // make it a fixed font weight
            font-weight: 400;
            // make it a fixed color
            color: rgb(135, 135, 135);
            // make it non highlightable
            user-select: none;
        }

        .text-input {
            // a nice form field
            box-sizing: border-box;
            height: 40px;
            max-width: 400px;
            width: 85vw;
            border-radius: 8px;
            border-width: 2px;
            border-style: solid;
            border-color: rgb(54, 54, 54);
            background-color: rgb(35, 35, 35);
            color: rgb(226, 226, 226);
            font-family: "Open Sans", sans-serif;
            font-size: 1rem;
            font-weight: 500;
            padding-left: 10px;
            padding-right: 10px;
            transition: 0.2s ease-in-out;
            outline: none;
            box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
            &:focus {
                // shadow
                border-color: rgb(113, 113, 113);
            }

            // make it non highlightable
            user-select: none;
        }

        .textarea-input {
            resize: vertical;
            // a nice form field
            box-sizing: border-box;
            max-width: 400px;
            width: 85vw;
            border-radius: 8px;
            border-width: 2px;
            border-style: solid;
            border-color: rgb(54, 54, 54);
            background-color: rgb(35, 35, 35);
            color: rgb(226, 226, 226);
            font-family: "Open Sans", sans-serif;
            font-size: 1rem;
            font-weight: 500;
            padding: 10px;
            transition: border-color 0.2s ease-in-out;
            min-height: 80px;
            outline: none;
            box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
            // decent scrollbar
            &::-webkit-scrollbar {
                width: 5px;
                padding: 15px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 15px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: rgb(113, 113, 113);
                &:focus {
                    // shadow
                    border-color: rgb(113, 113, 113);
                }
            }
            &:focus {
                // shadow
                border-color: rgb(113, 113, 113);
            }

            // make it non highlightable
            user-select: none;
        }

        .phone-number-text-input {
            // slightly darker version of text-input
            // a nice form field
            box-sizing: border-box;
            height: 40px;
            max-width: 300px;
            width: 70vw;
            border-radius: 10px;
            border-width: 2px;
            border-style: solid;
            border-color: rgb(37, 37, 37);
            background-color: transparent;
            color: rgb(226, 226, 226);
            font-family: "Open Sans", sans-serif;
            font-size: 1rem;
            font-weight: 500;
            padding-left: 10px;
            padding-right: 10px;
            transition: 0.2s ease-in-out;
            outline: none;
            &:focus {
                // shadow
                box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
                border-color: rgb(113, 113, 113);
            }

            // make it non highlightable
            user-select: none;
        }
    }

    .title {
        // open sans
        font-family: "Open Sans", sans-serif;
        // make it a fixed font size
        font-size: 3rem;
        // make it a fixed font weight
        font-weight: 100;
        // make it a fixed color
        color: white;

        max-width: 80vw;
        text-align: center;

        // wrap when overflow
        overflow-wrap: break-word;
    }

    .sub-title {
        // open sans
        font-family: "Open Sans", sans-serif;
        // make it a fixed font size
        font-size: 1.5rem;
        // make it a fixed font weight
        font-weight: 100;
        // make it a fixed color
        color: rgb(154, 154, 154);

        max-width: 80vw;
        text-align: center;

        // wrap when overflow
        overflow-wrap: break-word;
    }

    .groups {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        // wrap
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .group {
            border-radius: 10px;
            text-align: center;
            justify-content: center;
            margin: 5px;
            border-width: 2px;
            border-style: solid;
            height: 30px;
            min-width: 150px;
            line-height: 30px;
            cursor: pointer;
            color: white;
            font-family: "Open Sans", sans-serif;
            font-size: 1rem;
            font-weight: 600;
            transition: 0.2s ease-in-out;
            &:hover {
                background-color: rgba(49, 49, 49, 0.616);
                transform: scale(1.05);
            }
        }
    }

    .startDate {
        box-sizing: border-box;
        width: 80vw;
        color: rgb(165, 165, 165);
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        margin-top: 15px;
        transition: 0.2s ease-in-out;
        display: flex;
        flex-direction: row;
        // in between
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;

        .start-day {
            margin-left: 10px;
            margin-right: 10px;
            text-align: center;
        }

        .buttons {
            &:hover {
                cursor: pointer;
                color: white;
                // transform scale
                transform: scale(1.05);
            }
            // make it non highlightable
            user-select: none;
        }
    }

    .calendar {
        box-sizing: border-box;
        transition: 0.2s ease-in-out;
        background-color: rgb(28, 28, 28);
        width: 80vw;
        border-width: 2px;
        border-style: solid;
        border-radius: 20px;
        margin-top: 15px;
        // shadow
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);

        display: flex;
        flex-direction: row;

        .weekday {
            padding-top: 10px;
            padding-bottom: 10px;
            flex-grow: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            border: 1px solid rgb(36, 36, 36);

            .day-name {
                display: flex;
                flex-direction: column;
                .week-day {
                }
                .day {
                    font-size: 0.8rem;
                    color: grey;
                }
            }

            .calendar-event {
                width: fit-content;
                margin-top: 5px;
                border-radius: 10px;
                background-color: rgb(28, 28, 28);
                border-width: 2px;
                border-style: solid;
                border-color: rgb(36, 36, 36);
                text-align: left;
                padding: 5px;
                min-width: 80%;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                transform: translateY(-50%);

                .event-name {
                    font-family: "Open Sans", sans-serif;
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: white;
                }
                .event-time {
                    font-family: "Open Sans", sans-serif;
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: rgb(249, 249, 249);
                }
            }
        }
    }

    .select-button {
        // nice gradient button
        color: white;
        font-size: 1.3rem;
        font-weight: 600;
        background-color: rgb(32, 32, 32);
        border-width: 2px;
        border-style: solid;
        border-color: rgb(45, 45, 45);
        text-align: left;
        height: 30px;
        line-height: 30px;
        border-radius: 6px;
        margin-top: 10px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        width: 200px;
        text-align: center;
        &:hover {
            background-color: rgb(36, 36, 36);
            border-color: rgb(36, 36, 36);
            color: white;
        }
        &:active {
            background-color: rgb(28, 28, 28);
            border-color: rgb(36, 36, 36);
            color: white;
        }
    }

    .submit-button {
        // nice gradient button
        color: white;
        font-size: 1.3rem;
        font-weight: 600;
        text-align: left;
        height: 40px;
        line-height: 40px;
        border: 2px solid hotpink;
        border-radius: 6px;
        margin-top: 30px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        width: 200px;
        text-align: center;
        &:hover {
            background-color: rgb(72, 72, 72);
            border-color: transparent;
            color: white;
        }
        &:active {
            background-color: rgb(28, 28, 28);
            border-color: rgb(36, 36, 36);
            color: white;
        }
    }

    .error {
        margin-top: 10px;
        // make it a pink to red gradient text
        background: linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 0, 204));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1rem;
        font-weight: 600;
    }
}
