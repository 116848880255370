.applications-page {
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-apps {
        color: rgb(110, 110, 110);
        font-size: 1.2rem;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
        width: 100%;
    }

    .copypaste {
        font-size: 0.5rem;
        color: white;
    }

    .applications-list {
        width: 100%;
        .app-wrapper {
            border-radius: 10px;
            height: fit-content;
            background-color: rgb(39, 39, 39);
            &:hover {
                height: fit-content;
            }
            margin-bottom: 10px;
            // transition
            transition: all 0.2s ease-in-out;

            .app-content {
                padding: 20px;
                display: flex;
                flex-direction: row;

                .app-field {
                    margin: 15px;
                    .app-label {
                        //open sans
                        font-family: "Open Sans", sans-serif;
                        // font size
                        font-size: 0.8rem;
                        // font weight
                        font-weight: 600;
                        // color
                        color: rgb(183, 183, 183);
                    }
                    .app-entry {
                        //open sans
                        font-family: "Open Sans", sans-serif;
                        // font size
                        font-size: 0.8rem;
                        // font weight
                        font-weight: 600;
                        // color
                        color: white;
                    }
                }
                .text-button {
                    .accept {
                        // make text open sans gradient green to blue
                        background: linear-gradient(90deg, #00ffaa, #00ff37);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;

                        // font size
                        font-size: 0.8rem;
                        // font weight
                        font-weight: 600;
                        // color

                        //open sans
                        font-family: "Open Sans", sans-serif;
                        margin: 10px;
                        cursor: pointer;
                    }
                    .deny {
                        // make pink to red gradient text
                        background: linear-gradient(90deg, #ff0000, #ff00ff);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;

                        // font size
                        font-size: 0.8rem;
                        // font weight
                        font-weight: 600;
                        margin: 10px;
                        cursor: pointer;
                    }
                }
            }

            .application-list-application {
                background-color: rgb(17, 17, 17);
                display: flex;
                flex-direction: row;
                align-items: center;
                visibility: visible;

                // have a border
                border: 2px solid rgb(50, 50, 50);
                // border radius inherit
                border-radius: inherit;
                // padding
                padding: 0.5rem;
                // transition
                transition: all 0.2s ease-in-out;

                //shadow
                box-shadow: 0px 0px 10px 0px rgb(0, 0, 0);

                .application-name {
                    // open sans
                    font-family: "Open Sans", sans-serif;
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: white;
                }

                &:hover {
                    // make it a bit darker
                    background-color: rgb(30, 30, 30);
                    cursor: pointer;
                }
            }
        }
    }
}
