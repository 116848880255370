.creating-event {
    .exit {
        // create a nice exit button
        // make it a fixed height
        height: 25px;
        // make it a fixed width
        width: 40px;
        // make it a border radius
        border-radius: 5px;
        color: white;
        font-size: 1.2rem;
        font-weight: 600;
        // make text vertically centered
        line-height: 25px;
        // make it a pointer
        cursor: pointer;
        margin-bottom: 10px;
    }
    .input {
        input {
            // make an input box with a border and is darker than its background
            // make it a fixed height
            height: 28px;
            // make it a fixed width
            width: 300px;
            // make it a border radius
            border-radius: 5px;
            border: 1px solid rgb(50, 50, 50);
            background-color: rgb(11, 11, 11);
            // open sans
            font-family: "Open Sans", sans-serif;
            color: rgb(155, 155, 155);
            font-size: 1rem;
            font-weight: 600;
            // padding
            padding-left: 10px;
            // no outline on click
            outline: none;

            // outline on click to pink
            &:focus {
                outline: 1px solid rgb(119, 47, 124);
            }
        }

        .text {
            // make an open sans font for the text input
            font-family: "Open Sans", sans-serif;
            // make it a fixed height
            height: 30px;
            color: rgb(106, 106, 106);
            font-size: 1rem;
            font-weight: 600;
            // make text vertically centered
            line-height: 30px;
        }
    }
    .input-selection {
        // select box stuff

        // background color
        background-color: rgb(11, 11, 11);
        // border radius
        border-radius: 5px;
        color: rgb(199, 199, 199);
        // open sans
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        // padding
        padding-left: 10px;
        // no outline on click
        outline: none;

        // outline on click to pink
        &:focus {
            outline: 1px solid rgb(119, 47, 124);
        }

        // make it a fixed height
        height: 30px;

        // make it a fixed width
        width: 300px;

        // border
        border: 1px solid rgb(50, 50, 50);
    }
    .time-input {
        background-color: rgb(109, 109, 109);
        width: fit-content;
        border: 1px solid rgb(50, 50, 50);
        border-radius: 5px;
        padding: 5px;
        color: black;
    }
    .submit-button {
        // make a nice button with gradient for creating the group
        // make it a fixed height
        height: 30px;
        // make it a fixed width
        width: 250px;
        // make it a border radius
        border-radius: 5px;
        // make it a pointer
        cursor: pointer;
        // make it a gradient
        border: 2px solid hotpink;
        // open sans
        font-family: "Open Sans", sans-serif;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        // make text vertically centered
        line-height: 30px;

        margin-top: 10px;
    }
}
