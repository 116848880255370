.groups {
    h1 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: white;
    }

    .groups-list {
        display: flex;
        flex-wrap: wrap;
        // columns

        .group,
        .create-group {
            margin: 5px;
            // make width fill available space
            flex-grow: 1;
            min-width: calc(100% / 4);

            height: 40px;
            border-radius: 5px;
            border: 1px solid rgb(50, 50, 50);
            box-shadow: 0px 0px 10px 0px rgb(0, 0, 0);
            color: white;
            font-size: 1.2rem;
            font-weight: 500;
            // make text centered
            text-align: center;
            // make text vertically centered
            line-height: 40px;
            // make it a pointer
            cursor: pointer;
            // make it a nice transition
            transition: all 0.2s ease-in-out;
        }

        .create-group {
            // make a nice button maybe with a gradient
            border: 2px solid hotpink;
            // make it a border radius

            // make it a nice hover effect
            &:hover {
                // make it a bit darker
                background-color: hotpink;
                // make it a bit bigger
                transform: scale(1.02);
            }
        }
        // make a good group row
        .group {
            // make it a nice hover effect
            &:hover {
                // make it a bit darker
                background-color: rgb(30, 30, 30);
                // make it a bit bigger
                transform: scale(1.02);
            }
        }
    }
}
