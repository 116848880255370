.login-page {
    // make a nice login page

    // give a nice background color
    background-color: #2b2b2b;
    height: 100vh;
    width: 100vw;

    .title {
        font-family: "Open Sans", sans-serif;
        font-size: 1.3rem;
        font-weight: 700;
        color: black;
    }

    .subtitle {
        font-family: "Open Sans", sans-serif;
        font-size: 1.3rem;
        font-weight: 700;
        color: white;
    }

    .login {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 300px;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }

    .login-input {
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
        border: none;
        border-bottom: 1px solid #040404;
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 700;
        color: #040404;
    }

    .login-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 5px;
        color: rgb(0, 0, 0);
        background-color: transparent;
        border: 2px solid hotpink;
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
    }
}
